import {Alert, AlertTitle} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import React from "react";
import {UseAuthMessage} from "../hooks/authmessage";

export function AuthMessageSnackbar(props) {
    const [showAuthMessage, authMessageSeverity, authMessageTitle, authMessageBody, closeAuthMessage] = UseAuthMessage(props.authCode, props.onClose)

    return <Snackbar open={showAuthMessage} autoHideDuration={6000} onClose={closeAuthMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={closeAuthMessage} severity={authMessageSeverity} sx={{ width: '100%' }}>
            <AlertTitle>{authMessageTitle}</AlertTitle>{authMessageBody}
        </Alert>
    </Snackbar>
}
import React from "react";

export function useMounted() {
    const [hasMounted, setHasMounted] = React.useState(false)

    React.useEffect(() => {
        setHasMounted(true);
    }, []);

    return (content)=>{
        if (!hasMounted) {
            return null;
        } else {
            return content
        }
    }
}
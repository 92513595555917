import {Box, IconButton, Snackbar, Typography} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {useStyles} from "@material-ui/pickers/DatePicker/DatePickerToolbar";
import {HelpTooltip} from "./HtmlTooltip";
import {useHelpText} from "../context/helpText";

function DebateId(props) {
    const classes = useStyles();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const helpText = useHelpText()

    const [debateIdHelpTextVisible, setDebateIdHelpTextVisible] = React.useState(false)

    React.useEffect(()=>{
        helpText.registerTooltip("DEBATE_ID", setDebateIdHelpTextVisible)
    }, [])

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSnackbarExited = () => {
    }

    const copyDebateIdToClipboard = async () => {
        const baseUrl = location.host ? location.host : '';
        const debateUrl = `${location.protocol}//${baseUrl}?debateId=${props.id}`

        await navigator.clipboard.writeText(debateUrl)
        setSnackbarOpen(true)
    }

    return <>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            message="copied debateId to clipboard"
            key={"TopCenterSnackbar"}
            autoHideDuration={2000}
            onExited={handleSnackbarExited}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </React.Fragment>
            }
        />
        <HelpTooltip header={"Debate Identifier"} lineItems={["Click on the text to copy the link of this Debate to the clipboard", "Using the link, any registered user will be able to join the debate", "You can share the link via email, chat or any other means to invite debate members"]} open={debateIdHelpTextVisible}>
            <Box display={"flex"} justifyContent={"flex-start"}>
                <Typography id={"debateId"} variant={"overline"} onClick={copyDebateIdToClipboard}><b>#debate</b>&nbsp;/&nbsp;{props.id}</Typography>
            </Box>
        </HelpTooltip>
    </>
}

export default DebateId
import React, {useContext, useState} from "react";
import {useCookie} from "../hooks/Cookie";
import {SessionCookies, useStorage} from "../hooks/Store";
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export const AuthContext = React.createContext(null)

export const AuthCode = {
    RegistrationVerificationIncomplete: "REGISTRATION_VERIFICATION_INCOMPLETE",
    ForgotPasswordRegistrationVerificationIncomplete: "FORGOT_PASSWORD_REGISTRATION_INCOMPLETE",
    UserNotFound: "USER_NOT_FOUND",
    InvalidPassword: "INVALID_PASSWORD",
    InvalidLoginData: "INVALID_LOGIN_DATA",
    PasswordMismatch: "PASSWORD_MISMATCH",
    PasswordResetRequired: "PASSWORD_RESET_REQUIRED",
    UnknownError: "UNKNOWN_ERROR",
    NewPasswordRequired: "NEW_PASSWORD_REQUIRED",
    InternalError: "INTERNAL_ERROR",
    AuthCodeMismatch: "AUTH_CODE_MISMATCH",
    InvalidAuthCode: "INVALID_AUTH_CODE",
    VerificationCodeLimitExceeded: "VERIFICATION_CODE_LIMIT_EXCEEDED",
    AuthenticationSuccessful: "AUTHENTICATION_SUCCESSFUL",
    InitiatePasswordResetSuccessful: "INITIATE_PASSWORD_RESET_SUCCESSFUL",
    ChangePasswordSuccessful: "CHANGE_PASSWORD_SUCCESSFUL",
    PasswordResetCompleted: "PASSWORD_RESET_COMPLETED",
    ConfirmNewPasswordSuccessful: "CONFIRM_PASSWORD_SUCCESSFUL",
    SignupSuccessful: "SIGNUP_SUCCESSFUL",
    VerifySignupSuccessful: "VERIFY_SIGNUP_SUCCESSFUL",
    ResendVerificationCodeSuccessful: "RESEND_VERIFICATION_CODE_SUCCESSFUL",
    VerificationPasswordDoNotMatch: "VERIFICATION_PASSWORD_DO_NOT_MATCH",
    InvalidRegistrationToken: "INVALID_REGISTRATION_TOKEN",
    UsernameExists: "USER_NAME_EXISTS",
    RegistrationTokenExpired: "REGISTRATION_TOKEN_EXPIRED",
    AccountVerificationIsPending: "ACCOUNT_VERIFICATION_PENDING"
}

export function AuthProvider({ children }) {
    const [identity, setIdentity] = useStorage(SessionCookies.IDENTITY, null);
    const [userName, setUserName] = useStorage(SessionCookies.USERNAME, null)

    const cognitoUser = React.useRef(null)

    let poolData
    let userPool

    fetch('/config/cognitoPoolData.json')
        .then(response => {
            response.json().then(jsonValue => {
                console.log('using pool data')
                console.log(jsonValue)

                poolData = jsonValue
                userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            })
        })

    const signIn = (user, password) => {
        console.log(`user sign in initiated [${user}]`)

        const authenticationData = {
            //Username: user,
            EmailAddress: user,
            Password: password,
        };

        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        const userData = {
            Username: user,
            Pool: userPool,
        };

        cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);

        return new Promise((resolve, reject) => {
            try {
                cognitoUser.current.authenticateUser(authenticationDetails, {
                    onSuccess: function (result) {
                        setIdentity(result.getIdToken().getJwtToken())
                        setUserName(result.getIdToken().payload['cognito:username'])

                        console.log(`auth.signIn(): user [${user}] is authenticated [${JSON.stringify(result)}]`)

                        const authResult = {
                            authCode: AuthCode.AuthenticationSuccessful,
                            identity: result.getIdToken().getJwtToken(),
                            userName: result.getIdToken().payload['cognito:username']
                        }

                        resolve(authResult)
                    },

                    newPasswordRequired: function (userAttributes, requiredAttributes) {
                        console.log("auth.signIn(): new password required")
                        console.log("user attributes")
                        console.log(userAttributes)
                        console.log("required attributes")
                        console.log(requiredAttributes)

                        const authResult = {
                            authCode: AuthCode.NewPasswordRequired
                        }

                        resolve(authResult)
                    },

                    onFailure: function (err) {
                        console.log(`auth.signIn(): failed [${JSON.stringify(err)}]`)

                        const authResult = {
                            authCode: undefined
                        }

                        if (err.code === "UserNotConfirmedException") {
                            authResult.authCode = AuthCode.RegistrationVerificationIncomplete
                        } else if (err.code === "UserNotFoundException") {
                            authResult.authCode = AuthCode.UserNotFound
                        } else if (err.code === "NotAuthorizedException") {
                            authResult.authCode = AuthCode.PasswordMismatch
                        } else if (err.code === "InvalidParameterException") {
                            authResult.authCode = AuthCode.InvalidLoginData
                        } else if (err.code === "PasswordResetRequiredException") {
                            authResult.authCode = AuthCode.PasswordResetRequired
                        } else {
                            authResult.authCode = AuthCode.UnknownError
                            console.error(JSON.stringify(err))
                        }

                        resolve(authResult)
                    },
                });
            } catch (err) {
                console.error(`auth.signIn(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    function changePassword(user, newPassword) {
        console.log(`change password initiated [${user}]`)

        const userData = {
            Username: user,
            Pool: userPool,
        };

        if (cognitoUser.current===null) {
            cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);
        }

        return new Promise((resolve, reject) => {
            try {
                cognitoUser.current.completeNewPasswordChallenge(newPassword, [], {
                    onSuccess: function (result) {
                        const authResult = {
                            authCode: AuthCode.AuthenticationSuccessful,
                            identity: result.getIdToken().getJwtToken(),
                            userName: result.getIdToken().payload['cognito:username']
                        }

                        setIdentity(result.getIdToken().getJwtToken())
                        setUserName(result.getIdToken().payload['cognito:username'])

                        console.log(`auth.signIn(): user [${user}] is authenticated [${JSON.stringify(result)}]`)

                        resolve(authResult)
                    },

                    onFailure: function (err) {
                        console.log(`auth.signIn(): failed [${JSON.stringify(err)}]`)

                        const authResult = {
                            authCode: AuthCode.AuthenticationSuccessful,
                        }

                        if (err.code === "UserNotConfirmedException") {
                            authResult.authCode = AuthCode.RegistrationVerificationIncomplete
                        } else if (err.code === "UserNotFoundException") {
                            authResult.authCode = AuthCode.UserNotFound
                        } else if (err.code === "NotAuthorizedException") {
                            authResult.authCode = AuthCode.PasswordMismatch
                        } else if (err.code === "InvalidParameterException") {
                            authResult.authCode = AuthCode.InvalidLoginData
                        } else if (err.code === "PasswordResetRequiredException") {
                            authResult.authCode = AuthCode.PasswordResetRequired
                        } else {
                            authResult.authCode = AuthCode.UnknownError
                            console.error(JSON.stringify(err))
                        }

                        resolve(authResult)
                    },
                });
            } catch (err) {
                console.error(`auth.signIn(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    function initiatePasswordReset(user) {
        console.log(`password reset initiated [${user}]`)

        const userData = {
            Username: user,
            Pool: userPool,
        };

        if (cognitoUser.current===null) {
            cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);
        }

        return new Promise((resolve, reject) => {
            try {
                cognitoUser.current.forgotPassword({
                    onSuccess: function (result) {
                        console.log(`auth.initiatePasswordReset(): ${user} succeeded [${result}]`)
                        resolve({authCode: AuthCode.InitiatePasswordResetSuccessful})
                    },
                    onFailure: function (err) {
                        console.log(`password reset failed: ${user} failed [${JSON.stringify(err)}]`)

                        switch (err.code) {
                            case "UserNotFoundException":
                                resolve({authCode: AuthCode.UserNotFound})
                                break
                            case "LimitExceededException":
                                resolve({authCode: AuthCode.VerificationCodeLimitExceeded})
                                break
                            case "InvalidParameterException":
                                resolve({authCode: AuthCode.ForgotPasswordRegistrationVerificationIncomplete})
                                break
                            default:
                                resolve({authCode: AuthCode.UnknownError})
                        }
                    },
                    inputVerificationCode(data) {
                        resolve(AuthCode.InitiatePasswordResetSuccessful)
                        console.log(`inputVerificationCode: ${JSON.stringify(data)}`)
                    }
                })
            } catch (err) {
                console.error(`auth.initiatePasswordReset(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        });
    }

    function confirmNewPassword(user, newPassword, verificationCode) {
        console.log(`password reset initiated [${user}]`)

        const userData = {
            Username: user,
            Pool: userPool,
        };

        if (cognitoUser.current===null) {
            cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);
        }

        return new Promise((resolve, reject) => {
            try {
                cognitoUser.current.confirmPassword(verificationCode, newPassword, {
                    onSuccess: function (result) {
                        console.log(`auth.confirmNewPassword(): ${user} successfully confirmed password [${JSON.stringify(result)}]`)
                        resolve({authCode: AuthCode.ConfirmNewPasswordSuccessful})
                    },
                    onFailure: function (err) {
                        switch (err.code) {
                            case "CodeMismatchException":
                                console.error(`auth.confirmNewPassword(): ${user} entered a verification code that does not match[${JSON.stringify(err)}]`)
                                resolve({authCode: AuthCode.AuthCodeMismatch})
                                break
                            case "InvalidParameterException":
                                console.error(`auth.confirmNewPassword(): ${user} entered invalid verification code [${JSON.stringify(err)}]`)
                                resolve({authCode: AuthCode.InvalidAuthCode})
                                break
                            case "InvalidPasswordException":
                                console.error(`auth.confirmNewPassword(): ${user} entered invalid password [${JSON.stringify(err)}]`)
                                resolve({authCode: AuthCode.InvalidPassword})
                                break
                            case "ExpiredCodeException":
                                console.error(`auth.confirmNewPassword(): ${user} email address and verification code mismatch [${JSON.stringify(err)}]`)
                                resolve({authCode: AuthCode.InvalidAuthCode})
                                break
                            default:
                                console.error(`auth.confirmNewPassword(): ${user} failed to confirm password [${JSON.stringify(err)}]`)
                                resolve({authCode: AuthCode.UnknownError})
                                break
                        }
                    }
                });
            } catch(err) {
                console.error(`auth.confirmNewPassword(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    function signup(token, email, userName, password) {
        const attributeList = [];
        const validationData = []

        const dataEmail = {
            Name: 'email',
            Value: email,
        };

        const dataToken = {
            Name: 'token',
            Value: token,
        };

        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

        attributeList.push(attributeEmail);

        validationData.push(dataToken);

        return new Promise((resolve, reject)=>{
            try {
                userPool.signUp(userName, password, attributeList, validationData, function (
                    err,
                    result
                ) {
                    const authResult = {
                        authCode: undefined
                    }

                    if (err) {
                        console.error(`auth.signup(): failed [${JSON.stringify(err)}]`)

                        switch (err.code) {
                            case "UserLambdaValidationException":
                                authResult.authCode = AuthCode.InvalidRegistrationToken
                                break
                            case "UsernameExistsException":
                                authResult.authCode = AuthCode.UsernameExists
                                break
                            case "InvalidParameterException":
                                authResult.authCode = AuthCode.InvalidLoginData
                                break
                            case "InvalidPasswordException":
                                authResult.authCode = AuthCode.InvalidPassword
                                break
                            default:
                                authResult.authCode = AuthCode.UnknownError
                        }
                    } else {
                        console.log(`auth.signup(): success [${result}]`)
                        authResult.authCode = AuthCode.SignupSuccessful
                    }

                    resolve(authResult)
                });
            } catch (err) {
                console.error(`auth.signup(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    function verifySignup(email, verificationCode) {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        console.log(`verifying signup [userName=${email}, verificationCode=${verificationCode}]`)
        console.log(userData)

        return new Promise((resolve, reject)=> {
            try {
                if (cognitoUser.current===null) {
                    cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);
                }

                cognitoUser.current.confirmRegistration(verificationCode, true, function (err, result) {
                    if (err) {
                        console.error(`auth.verifySignup(): failure [${JSON.stringify(err)}]`)

                        switch (err.code) {
                            case "InvalidParameterException":
                                resolve({authCode: AuthCode.InvalidAuthCode})
                                break
                            case "CodeMismatchException":
                                resolve({authCode: AuthCode.AuthCodeMismatch})
                                break
                            case "UserLambdaValidationException":
                                resolve({authCode: AuthCode.RegistrationTokenExpired})
                                break
                            case "NotAuthorizedException":
                                resolve({authCode: AuthCode.RegistrationVerificationIncomplete})
                                break
                            case "UserNotFoundException":
                                resolve({authCode: AuthCode.UserNotFound})
                            default:
                                resolve({authCode: AuthCode.UnknownError})
                        }
                        /*
                            if (err.code==="") {
                                setErrorTitle("Invalid verification code")
                                setErrorMessage("Please make sure you provided a value and try again.")
                            } else if (err.code==="") {
                                setErrorTitle("The verification code does not match")
                                setErrorMessage("Please verify you are using the most recent code and try again.")
                            } else if (err.code==="") {
                                setErrorTitle("Registration Token expired")
                                setErrorMessage("Please contact your administrator to obtain another token.")
                            } else if (err.code==="") {
                                setErrorTitle("Account verification is pending")
                                setErrorMessage("Please start over the registration process.")
                            } else {
                                setErrorTitle("An unknown error occurred")
                                setErrorMessage("Please try again later.")
                            }

                            setShowErrorMessage(true)
                        }
                        */
                    } else {
                        console.error(`auth.verifySignup(): success [${JSON.stringify(result)}]`)
                        resolve({authCode: AuthCode.VerifySignupSuccessful})
                    }
                }, null);
            } catch(err) {
                console.error(`auth.verifySignup(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    function resendVerificationCode(email) {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        return new Promise((resolve, reject)=> {
            try {
                if (cognitoUser.current===null) {
                    cognitoUser.current = new AmazonCognitoIdentity.CognitoUser(userData);
                }

                cognitoUser.current.resendConfirmationCode(function (err, result) {
                    if (err) {
                        console.error(`auth.resendVerificationCode(): failure [${JSON.stringify(err)}]`)

                        switch (err.code) {
                            case "LimitExceededException":
                                resolve({authCode: AuthCode.VerificationCodeLimitExceeded})
                                break
                            default:
                                resolve({authCode: AuthCode.UnknownError})
                        }
                    } else {
                        console.log(`auth.resendVerificationCode(): success [${JSON.stringify(result)}]`)
                        resolve({authCode: AuthCode.ResendVerificationCodeSuccessful})
                    }
                }, null);
            } catch (err) {
                console.error(`auth.resendVerificationCode(): internal error [${JSON.stringify(err)}]`)
                reject(AuthCode.InternalError)
            }
        })
    }

    const signOut = cb => {
        setIdentity(null)
        cognitoUser.current = null
        setUserName("")

        if (cb) {
            cb()
        }
    };

    const auth = {
        identity,
        userName,
        signIn,
        signOut,
        signup,
        verifySignup,
        resendVerificationCode,
        initiatePasswordReset,
        confirmNewPassword,
        changePassword
    }

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
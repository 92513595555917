import Box from "@material-ui/core/Box";
import {Collapse, Dialog, Fab, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {AuthCode, useAuth} from "../context/auth";
import {makeStyles} from "@material-ui/core/styles";
import {AuthMessageSnackbar} from "./AuthMessageSnackbar";
import * as yup from 'yup';
import RegisterDialog from "./RegisterDialog";
import Link from "./Link";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import {useApi} from "../context/api";

const deployedVersion = "v1"

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 0, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh"
    },
    loginForm: {
        width: "100%",
        margin: 2
    },
    loginText: {
        width: "100%"
    }
}));

const DialogTitle = {
    Login: "Please log in",
    PasswordReset: "Reset your password"
}

function LoginDialog(props) {
    const { onClose, open } = props;

    const handleCancel = () => {
        onClose(false)
    }

    const api = useApi()
    const classes = useStyles()
    const auth = useAuth();
    const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);
    const [loginAction, setLoginAction] = useState("signin")
    const [authCode, setAuthCode] = React.useState("");
    const [userNameLocked, setUserNameLocked] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState(DialogTitle.Login)

    React.useEffect(()=>{
        switch (loginAction) {
            case 'passwordreset':
                setDialogTitle(DialogTitle.PasswordReset)
                break
            default:
                setDialogTitle(DialogTitle.Login)
                break
        }
    }, [loginAction])

    function showAuthInternalError(source, err) {
        console.error(`${source}: internal error [${JSON.stringify(err)}]`)
    }

    function updateLoginAction(updateLoginAction) {
        setLoginAction(updateLoginAction)
        setUserNameLocked(updateLoginAction!=="signin")
    }

    const verificationObject = {
        userName: yup
            .string('Enter user name')
            .min(6, 'User name must be at least 6 characters long')
            .required('User name is required')
    }

    if (loginAction!=="verify") {
        verificationObject.password = yup
            .string('Enter your password')
            .min(6, 'Password must be at least 6 characters long')
            .required('Password is required')
    }

    if (loginAction==="passwordreset") {
        verificationObject.verifyPassword = yup
            .string('Verify your password')
            .min(6, 'Password must be at least 6 characters long')
            .required('Password is required')
    }

    if (loginAction==="verify" || loginAction==="passwordreset") {
        verificationObject.verificationCode = yup
            .string('Enter your verification code')
            .length(6, 'Verification code must be 6 characters long')
            .required('Verification code is required')
    }

    const loginFormik = useFormik({
        initialValues: {
            userName: '',
            password: '',
            verifyPassword: '',
            verificationCode: ''
        },
        validationSchema: yup.object(verificationObject),
        validate: (values) => {
            const errors = {}

            if (loginAction==="passwordreset" && values.password!==values.verifyPassword) {
                errors.verifyPassword = "Passwords do not match"
            }

            return errors
        },
        onReset: (values) => {
            updateLoginAction("signin")
        },
        onSubmit: (values) => {
            switch (loginAction) {
                case "verify":
                    auth.verifySignup(loginFormik.values.userName, loginFormik.values.verificationCode)
                        .then((authCode)=>{
                            if (authCode===AuthCode.VerifySignupSuccessful) {
                                updateLoginAction("signin")
                            }

                            showAuthMessage(authCode)
                        })
                        .catch((err)=>{
                            showAuthInternalError("verifySignup", err)
                        })
                    break
                case "signin":
                    loginFormik.values.verificationCode = ""

                    auth.signIn(values.userName, values.password).then((authResult)=>{
                        console.log(`sign in authCode [${authCode}]`)
                        switch (authResult.authCode) {
                            case AuthCode.AuthenticationSuccessful:
                                onClose(true)
                                break
                            case AuthCode.RegistrationVerificationIncomplete:
                                loginFormik.values.password=""

                                auth.resendVerificationCode(loginFormik.values.userName).then((authResult)=>{
                                    switch (authResult.authCode) {
                                        case AuthCode.ResendVerificationCodeSuccessful:
                                            updateLoginAction("verify")
                                            break
                                    }

                                    new Promise(resolve => {
                                        setTimeout(()=>{
                                            showAuthMessage(authResult.authCode)
                                        }, 6000)
                                    });
                                }).catch((err)=>{
                                    showAuthInternalError("resendVerificationCode" ,err)
                                })

                                break
                            case AuthCode.PasswordResetRequired:
                                loginFormik.values.password=""
                                loginFormik.values.verifyPassword=""
                                loginFormik.values.verificationCode=""

                                resetPassword()
                                break
                            case AuthCode.NewPasswordRequired:
                                loginFormik.values.password=""
                                loginFormik.values.verifyPassword=""
                                loginFormik.values.verificationCode=""

                                updateLoginAction("changepassword")
                                break

                        }

                        showAuthMessage(authResult.authCode)
                    }).catch((err)=>{
                        showAuthInternalError("signIn" ,err)
                    })
                    break
                case "passwordreset":
                    auth.confirmNewPassword(loginFormik.values.userName, loginFormik.values.password, loginFormik.values.verificationCode).then((authCode) => {
                        switch (authCode) {
                            case AuthCode.ConfirmNewPasswordSuccessful:
                                updateLoginAction("signin")
                                loginFormik.resetForm()
                                break
                        }
                        showAuthMessage(authCode)
                    }).catch((err)=>{
                        showAuthInternalError("confirmNewPassword", err)
                    })
                    break
                case "changepassword":
                    auth.changePassword(loginFormik.values.userName, loginFormik.values.password).then((authResult) => {
                        switch (authResult.authCode) {
                            case AuthCode.AuthenticationSuccessful:
                                onClose(true)
                                break
                        }

                        showAuthMessage(authResult.authCode)
                    }).catch((err)=>{
                        showAuthInternalError("changePassword", err)
                    })
                    break
                default:
                    console.error(`invalid loginAction [${loginAction}]`)
            }
        },
    });

    useEffect(()=>{
        loginFormik.resetForm()
    }, [open])

    function openSubscriptionDialog() {
        updateLoginAction("signin")
        setShowSubscribeDialog(true)
    }

    function onCloseRegisterDialog() {
        updateLoginAction("signin")
        setShowSubscribeDialog(false)
    }

    function showAuthMessage(authCode) {
        setAuthCode("")
        setAuthCode(authCode)
    }

    function resetPassword() {
        loginFormik.values.password = ""
        loginFormik.values.verifyPassword = ""
        loginFormik.values.verificationCode = ""

        auth.initiatePasswordReset(loginFormik.values.userName).then((authCode) => {
            switch (authCode) {
                case AuthCode.InitiatePasswordResetSuccessful:
                    updateLoginAction("passwordreset")
                    break
            }
            showAuthMessage(authCode)
        }).catch((err)=>{
            showAuthInternalError("initiatePasswordReset", err)
        })
    }

    function onCloseAuthMessage() {
        setAuthCode(null)
    }

    return <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <RegisterDialog onClose={onCloseRegisterDialog} open={showSubscribeDialog}/>
            <Box display={"flex"} px={5} pt={10} flexDirection={"column"}>
                <AuthMessageSnackbar authCode={authCode} onClose={onCloseAuthMessage}/>
                <Box pb={5} display={"flex"} flexDirection={"column"}>
                    <Typography variant="h4" align={"center"}>{dialogTitle}</Typography>
                    <Typography variant="caption" align={"center"}>To create an account, <Link component="button" onClick={openSubscriptionDialog}>click here</Link>.</Typography>
                </Box>
                <form className={classes.loginForm} noValidate autoComplete="off">
                    <Box pb={1}>
                        <TextField disabled={userNameLocked} label={"User name"} id={"userName"} className={classes.loginText} value={loginFormik.values.userName} onChange={loginFormik.handleChange} error={loginFormik.errors.userName && loginFormik.touched.userName && Boolean(loginFormik.errors.userName)} helperText={loginFormik.errors.userName && loginFormik.touched.userName && loginFormik.errors.userName}/>
                    </Box>
                    <Collapse in={loginAction!=="verify"}>
                        <Box pb={1}>
                            <TextField label={loginAction==="passwordreset" || loginAction==="changepassword" ? "Your new password":"Password"} type={"password"} id={"password"} className={classes.loginText} value={loginFormik.values.password} onChange={loginFormik.handleChange} error={loginFormik.errors.password && loginFormik.touched.password && Boolean(loginFormik.errors.password)} helperText={loginFormik.errors.password && loginFormik.touched.password && loginFormik.errors.password}/>
                        </Box>
                    </Collapse>
                    <Collapse in={loginAction==="passwordreset"}>
                        <Box pb={1}>
                            <TextField label="Repeat your new password" type={"password"} id={"verifyPassword"} className={classes.loginText} value={loginFormik.values.verifyPassword} onChange={loginFormik.handleChange} error={loginFormik.errors.verifyPassword && loginFormik.touched.verifyPassword && Boolean(loginFormik.errors.verifyPassword)} helperText={loginFormik.errors.verifyPassword && loginFormik.touched.verifyPassword && loginFormik.errors.verifyPassword}/>
                        </Box>
                    </Collapse>
                    <Collapse in={loginAction==="verify" || loginAction==="passwordreset"}>
                        <Box>
                            <TextField label="Verification code" id={"verificationCode"} className={classes.loginText} value={loginFormik.values.verificationCode} onChange={loginFormik.handleChange} error={loginFormik.errors.verificationCode && loginFormik.touched.verificationCode && Boolean(loginFormik.errors.verificationCode)} helperText={loginFormik.errors.verificationCode && loginFormik.touched.verificationCode ? loginFormik.errors.verificationCode : "We have sent you a verification code to the email address provided above"}/>
                        </Box>
                    </Collapse>
                    <Box my={3} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                        <Collapse in={loginAction==="verify" || loginAction==="passwordreset"}>
                            <Box px={1}>
                                <Fab id={"cancelButton"} color="secondary" aria-label="join" size={"small"} onClick={loginFormik.handleReset}>
                                    <CancelIcon/>
                                </Fab>
                            </Box>
                        </Collapse>
                        <Box>
                            <Fab id={"loginButton"} color="primary" aria-label="join" type={"submit"} size={"small"} onClick={loginFormik.handleSubmit}>
                                <ArrowIcon/>
                            </Fab>
                        </Box>
                    </Box>
                </form>
                <Collapse in={loginAction==="signin"}>
                    <Box m={1}>
                        <Typography variant="caption">Did you forget your password? Reset it <Link component="button" onClick={resetPassword}>here</Link>.</Typography>
                    </Box>
                </Collapse>
            </Box>
    </Dialog>
}

export default LoginDialog
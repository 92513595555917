import React from "react"

import {AuthProvider} from "./src/context/auth"
import {ApiProvider} from "./src/context/api";
import {HelpTextProvider} from "./src/context/helpText";
import {BackdropProvider} from "./src/context/backdrop";

export const wrapRootElement = ({ element }) => (
    <AuthProvider>
        <ApiProvider>
            <HelpTextProvider>
                <BackdropProvider>
                    {element}
                </BackdropProvider>
            </HelpTextProvider>
        </ApiProvider>
    </AuthProvider>
)
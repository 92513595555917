import React from "react"
import { useSiteMetadata } from "../hooks/UseSiteMetadata"

export const SEO = ({ title, description, keywords, children }) => {
    return (
        <>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="viewport" content={"width=device-width, initial-scale=1.0"}/>
            <meta name="keywords"
                  content={"Debate, Online, Club, Compete, Practice, Improve, British, Parliamentary, WSDC"}/>
            {children}
        </>
    )
}

import React, {useContext} from "react"
import {LocalCookies, StorageKind, useStorage} from "../hooks/Store";

export const RoomContext = React.createContext(null)

const isBrowser = typeof window !== "undefined"

export function RoomContextProvider(props) {
    let showButtonLabels = null
    let setShowButtonLabels = null

    if (isBrowser) {
        [showButtonLabels, setShowButtonLabels] = useStorage(LocalCookies.SHOW_BUTTON_LABELS, true, StorageKind.Local)
    }

    const roomContext = {
        setAudioElement,
        setVideoTileManager,
        showButtonLabels,
        setShowButtonLabels
    }

    function setAudioElement(node) {
        roomContext.audioElement = node
    }

    function setVideoTileManager(videoTileManager) {
        roomContext.videoTileManager = videoTileManager
    }

    return (
        <RoomContext.Provider value={roomContext}>
            {props.children}
        </RoomContext.Provider>
    );
}

export function useRoomContext() {
    return useContext(RoomContext);
}
import React, {useCallback, useEffect} from "react"
import {CssBaseline, Fab, Fade, Typography} from "@material-ui/core";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Container from "@material-ui/core/Container";
import Protected from "../components/Protected";
import Motion from "../components/Motion";
import DebateId from "../components/DebateId";
import Box from "@material-ui/core/Box";
import {useApi} from "../context/api";
import {makeStyles} from "@material-ui/core/styles";
import {useRoomContext} from "../context/RoomContext";
import {navigate} from "../../.cache/commonjs/gatsby-browser-entry";
import {useAuth} from "../context/auth";
import {BrowserView, MobileView} from 'react-device-detect';
import DebateAppBar from "../components/DebateAppBar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center"
    }
}));

export default function Room({title, children}) {
    const api = useApi()
    const auth = useAuth()

    if (!api) {
        return null
    }

    // prevent rendering issues in Safari
    // the session store is cleared when navigating away
    // and this prevents reconnecting to the debate - instad, navigate to /
    React.useLayoutEffect(()=>{
        if (!api.getDebateId() && !api.joinDebateIdOnReturn && !auth.userName && !auth.identity) {
            navigate("/")
        }
    }, [])

    const classes = useStyles()
    const roomContext = useRoomContext()

    const registerAudioElement = useCallback(node => {
        roomContext.setAudioElement(node)
    })

    return (
        <Protected>
            <Container maxWidth={"md"}>
                <div>
                    <audio ref={registerAudioElement}/>
                    {children}
                </div>
            </Container>
        </Protected>
    )
}

/*
            <BrowserView>
                <div>
                    <CssBaseline/>
                    <div>
                        <DebateAppBar/>
                        <Fade in={true} timeout={2000}>
                            <Container maxWidth={"lg"}>
                                <div className={classes.root}>
                                    <Box height={"12vh"}></Box>
                                    <Typography variant={"h4"}>
                                        {title}
                                    </Typography>
                                    <DebateId id={api.getMeetingDetails().debateId}/>
                                    <audio ref={registerAudioElement}/>
                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                        <Motion body={api.getMeetingDetails().motion}/>
                                        {children}
                                    </Box>
                                </div>
                            </Container>
                        </Fade>
                        <Footer/>
                    </div>
                </div>
            </BrowserView>
 */

import {useRef, useState} from 'react';
import {DEBATE} from "debate-data";

export const LocalCookies = {
    LOBBY_SHOW_HELP_PANEL: "LOBBY_SHOW_HELP_PANEL",
    PREPROOM_SHOW_HELP_PANEL: "PREPROOM_SHOW_HELP_PANEL",
    ADJUDICATOR_SHOW_HELP_PANEL: "ADJUDICATOR_SHOW_HELP_PANEL",
    DEBATE_SHOW_HELP_PANEL: "DEBATE_SHOW_HELP_PANEL",
    DEBRIEF_SHOW_HELP_PANEL: "DEBRIEF_SHOW_HELP_PANEL",
    PREFERRED_VIDEO_INPUT_DEVICE: "PREFERRED_VIDEO_INPUT_DEVICE",
    PREFERRED_AUDIO_INPUT_DEVICE: "PREFERRED_AUDIO_INPUT_DEVICE",
    PREFERRED_AUDIO_OUTPUT_DEVICE: "PREFERRED_AUDIO_OUTPUT_DEVICE",
    SHOW_BUTTON_LABELS: "SHOW_BUTTON_LABELS"
}

export const SessionCookies = {
    IDENTITY: "IDENTITY",
    USERNAME: "USERNAME",
    RETURN_FROM: "RETURN_FROM",
    DEBATE_ID: "DEBATE_ID"
}

export const CookieValues = {
    SHOW: "SHOW",
    HIDE: "HIDE",
    NEVER_EXPIRE: 5 * 365
}

export const StorageKind = {
    Session: "sessionStorage",
    Local: "localStorage"
}

const clearAllCookies = () => {
    const localStorage = window[StorageKind.Local]
    const sessionStorage = window[StorageKind.Session]

    for (const [key, value] of Object.entries(LocalCookies)) {
        localStorage.removeItem(key)
    }

    for (const [key, value] of Object.entries(SessionCookies)) {
        sessionStorage.removeItem(key)
    }
}

const checkCookieConsent = () => {
    if (typeof document !== undefined) {
        const localStorage = window[StorageKind.Local]
        const cookieConsentDataString = localStorage.getItem("TERMLY_API_CACHE")

        if (cookieConsentDataString!==null) {
            const cookieConsentData = JSON.parse(cookieConsentDataString)

            if (cookieConsentData.TERMLY_COOKIE_CONSENT?.value?.performance === true) {
                return true
            }
        }
    }

    return false
}

const getItem = (storageKind, key) => {
    const storage = window[storageKind]

    if (typeof document !== undefined) {
        if (checkCookieConsent()) {
            return storage.getItem(key)
        } else {
            clearAllCookies()
        }
    }
};

const setItem = (storageKind, key, value) => {
    const storage = window[storageKind]

    if (typeof document !== undefined) {
        if (checkCookieConsent()) {
            storage.setItem(key, value)
        } else {
            clearAllCookies()
        }
    }
}

export const useStorage = (key, defaultValue, storageKind= StorageKind.Session) => {
    const getStoredItem = () => {
        const value = getItem(storageKind, key)

        if (value) {
            return JSON.parse(value)
        } else {
            return defaultValue
        }
    }

    const setStoredItem = (value) => {
        console.log(`updating stored item [${key}] to value:`)
        console.log(value)

        setCookieValue(value);
        setItem(storageKind, key, JSON.stringify(value))
    }

    const [cookieValue, setCookieValue] = useState(getStoredItem())

    return [cookieValue, setStoredItem]
};

export const useRefStorage = (key, defaultValue, storageKind= StorageKind.Session) => {
    const getStoredItem = () => {
        const value = getItem(storageKind, key)

        console.log(`get item [${key}=${value}]`)

        if (value) {
            return JSON.parse(value)
        } else {
            return defaultValue
        }
    }

    const setStoredItem = (value) => {
        console.log(`updating stored item [${key}] to value:`)
        console.log(value)

        ref.current = value
        setItem(storageKind, key, JSON.stringify(value))
    };

    const ref = useRef(getStoredItem())

    return [ref, setStoredItem]
};
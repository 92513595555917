import React from "react"
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {createTheme, responsiveFontSizes, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import {SEO} from "../components/Seo";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import DebateAppBar from "../components/DebateAppBar";

// https://alvarotrigo.com/blog/css-animations-scroll/

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 0, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: 1
    },
    revealBox: {
        backgroundColor: "#1F1F1F",
        //transform: "translateY(150px)",
        transition: "0.1s all linear",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "5rem",
        zIndex: 2,
    },
    hero: {
        transition: "0.2s all ease",
    }
}));

function TextItem({title, children}) {
    const medium = useMediaQuery(useTheme().breakpoints.up('md'));

    return <>
        <Box px={6}>
            <Typography variant={"h4"} align={medium?"right":"left"}>{title}</Typography>
            <Typography variant={"subtitle1"} align={medium?"right":"left"} style={{color: "#404050", letterSpacing: 1}}>
                {children}
            </Typography>
        </Box>
    </>

}

function ImageItem({imageUrl}) {
    const small = useMediaQuery(useTheme().breakpoints.up('sm'));
    const imgWidth = !small ? "300px" : "440px"

    return <>
        <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
            <img src={imageUrl} width={imgWidth} height={"100%"}></img>
        </Box>
    </>
}

function Pricing(props) {
    const classes = useStyles()
    const medium = useMediaQuery(useTheme().breakpoints.up('md'));
    const small = useMediaQuery(useTheme().breakpoints.up('sm'));
    const extraPadding = medium?10:0

    return (
        <>
            <DebateAppBar location={props.location}/>
            <Container maxWidth={"md"}>
                    <Grid container spacing={0} sx={{backgroundColor: "#EAEAEA"}}>
                        <Grid item xs={12}>
                            <Box width={"100%"} sx={{backgroundImage: "linear-gradient(#2d2d33, #D5D5D8)"}} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"flex-end"}>
                                <Box height={"8rem"}/>
                                <Box display={"flex"} letterSpacing={2} display={"flex"} justifyContent={"center"} px={3}>
                                    <Typography variant="h3" style={{color: "white", letterSpacing: 4, fontWeight: 500}} align={"center"}>
                                        Premium Subscriptions
                                    </Typography>
                                </Box>
                                <svg fill={"#EAEAEA"} viewBox={"0 0 60 100"} preserveAspectRatio={"none"} style={{position: "relative", bottom: 0, left: 0, width: "100%", height: "5rem", filter: "drop-shadow(rgb(120, 120, 120) 0px -12px 5px)", overflowX: "hidden", overflowY: "hidden"}}>
                                    <polygon points={"0,70 0,100 100,100"}></polygon>
                                </svg>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Box height={"4rem"}/>
                        </Grid>
                        <Grid container spacing={0} px={extraPadding}>
                            <Grid item xs={12} md={8}>
                                <TextItem title={"Testing, testing..."}>
                                    <p>debate.club is running in private beta as of now.</p>
                                    <p>
                                        Give it a test drive and enjoy our services for free.
                                        You can reward us with your feedback, suggestions, and submitting bug reports.
                                    </p>
                                </TextItem>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ImageItem imageUrl={"/finance.svg"}/>
                            </Grid>
                            <Grid item xs={12} md={5} hidden={!medium}>
                                <ImageItem imageUrl={"/pricing.svg"}/>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextItem title={""}>
                                    <p>
                                        Once we collected enough feedback and polished our application to be ready for
                                        production, we will publish information about premium subscriptions on this page.
                                    </p>
                                    <p>
                                        We are working on offering reasonable and affordable pricing. Check back frequently to
                                        see any updates.
                                    </p>
                                </TextItem>
                            </Grid>
                            <Grid item xs={12} md={6} hidden={medium}>
                                <ImageItem imageUrl={"/pricing.svg"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Box height={"2rem"}/>
                            </Grid>
                        </Grid>
                        <Footer/>
                    </Grid>
            </Container>
        </>
    )
}

export default Pricing

export const Head = () => (
    <SEO title="Premium Subscriptions" description="Learn about the different subscription options we offer to use our services." />
)

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {createTheme, Fab, MuiThemeProvider, useMediaQuery} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import Link from "./Link";
import {Helmet} from "react-helmet";
import {useTheme} from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const theme = createTheme({
    palette: {
        zap: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#ffffff',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        }
    },
});

// getUpdatedCookieWhitelistByTermly

function SetScript(props) {
    return <>
        <Helmet>
            <script type={"module"}>
                {`
                    export function getUpdatedCookieWhitelistByTermly(d) { 
                        console.log('dozing') 
                    }                    

                    //getUpdatedCookieWhitelistByTermly('zapzap')                    
                `}
            </script>
        </Helmet>
        <div
            dangerouslySetInnerHTML={{
                __html: props.html,
            }}
        />
    </>
}

function openCookiePreferencesDialog() {
    if (displayPreferenceModal) {
        displayPreferenceModal()
    }
}

function MenuLink({to, children}) {
    return <a style={{"text-decoration": "none", "color": "inherit"}} href={to}>{children}</a>
}

export default function Footer() {
    const medium = useMediaQuery(theme.breakpoints.up('md'));
    const small = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container>
        <Box sx={{position: "sticky", top: "50vh", backgroundImage: "linear-gradient(#2d2d33, #A5A5A8)", boxShadow: "0px -2px 40px #F8F8F8", borderTopLeftRadius:"35px", borderTopRightRadius:"35px"}} width="100%" height={"24rem"} display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"} flexDirection={"row"}>
            <Box flexGrow={3} height={"100%"}></Box>
            <Box position="relative" flexGrow={2} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                <Box mt={5} mb={3}>
                    <Typography variant="h6" style={{color:"white", fontWeight: 600}}>
                        <Box sx={{letterSpacing: 2}}>
                            Company
                        </Box>
                    </Typography>
                </Box>
                <Box sx={{lineHeight: 2, letterSpacing: 1.5}}>
                    <Typography variant="h7" style={{color:"#E0E0E0"}}>
                        <MenuLink to={"/product"}>Product</MenuLink><br/>
                        <MenuLink to={"mailto:contact@cloudmill.ltd"}>Contact</MenuLink><br/>
                    </Typography>
                </Box>
                <Box height={"10vh"} width={"100%"} position="absolute" bottom={0} sx={{borderTop: "1px solid #707070"}} overflow={"visible"}>
                    <Box mt={2} display={"inline-block"} sx={{whiteSpace: "nowrap"}}>
                        <Typography variant="caption" style={{color:"#303030", letterSpacing:0}} align={"left"}>
                            Copyright @ 2022 CloudMill Ltd. Hungary
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box position="relative" flexGrow={1} height={"100%"}>
                <Box height={"10vh"} width={"100%"} position="absolute" bottom={0} sx={{borderTop: "1px solid #707070"}}>
                </Box>
            </Box>

            {
                small ?
                    <Box position="relative" flexGrow={2} height={"100%"} display={"flex"} flexDirection={"column"}
                         justifyItems={"flex-start"}>
                        <Box mt={5} mb={3}>
                            <Typography variant="h6" style={{color: "white"}}>
                                <Box sx={{letterSpacing: 2, fontWeight: 600}}>
                                    Resources
                                </Box>
                            </Typography>
                        </Box>
                        <Typography variant="h7" style={{color: "#E0E0E0"}}>
                            <Box sx={{lineHeight: 2, letterSpacing: 1.5}}>
                                <MenuLink to={"https://debate.club/blog"}>Blog</MenuLink><br/>
                                <MenuLink to={"https://debate.club/doc"}>Docs</MenuLink><br/>
                                <MenuLink to={"https://hellomotions.com/"}>Motion ideas</MenuLink><br/>
                                <MenuLink to={"/history"}>History of debate</MenuLink><br/>
                            </Box>
                        </Typography>
                        <Box height={"10vh"} width={"100%"} position="absolute" bottom={0}
                             sx={{borderTop: "1px solid #707070"}}>
                        </Box>
                    </Box>
                    :
                    <></>
            }

            <Box position="relative" flexGrow={1} height={"100%"}>
                <Box height={"10vh"} width={"100%"} position="absolute" bottom={0} sx={{borderTop: "1px solid #707070"}}>
                </Box>
            </Box>
            <Box position="relative" flexGrow={2} height={"100%"} display={"flex"} flexDirection={"column"} justifyItems={"flex-start"}>
                <Box mt={5} mb={3}>
                    <Typography variant="h6" style={{color:"white"}}>
                        <Box sx={{letterSpacing: 2, fontWeight: 600}}>
                            Privacy
                        </Box>
                    </Typography>
                </Box>
                <MuiThemeProvider theme={theme}>
                    <Typography variant="h7" style={{color:"#E0E0E0"}}>
                        <Box sx={{lineHeight: 2, letterSpacing: 1.5}}>
                            <MenuLink to={"/privacy"}>Privacy Policy</MenuLink><br/>
                            <MenuLink to={"/cookie"}>Cookie Policy</MenuLink><br/>
                            <MenuLink to={"/terms"}>Terms of Use</MenuLink><br/>
                            <Typography variant={"link"} color={"inherit"} style={{ cursor: "pointer" }} onClick={openCookiePreferencesDialog}>Cookie Preferences</Typography><br/>
                            <MenuLink to={"/dsar"}>Data Access Request</MenuLink><br/>
                            <MenuLink to={"/dsar"}>Do not sell my info</MenuLink><br/>
                        </Box>
                    </Typography>
                </MuiThemeProvider>
                <Box height={"10vh"} width={"100%"} position="absolute" bottom={0} sx={{borderTop: "1px solid #707070"}}>
                </Box>
            </Box>

            {
                medium ?
                    <Box flexGrow={6} height={"100%"} sx={{backgroundColor: "#A5A5A8"}} display={"flex"}
                         flexDirection={"column"} alignItems={"center"}>
                        <Box mt={5} display={"flex"} width={"75%"} flexDirection={"row"} justifyContent={"space-between"}>
                            <Fab size={"small"}><FacebookIcon/></Fab>
                            <Fab size={"small"}><TwitterIcon/></Fab>
                            <Fab size={"small"}><LinkedInIcon/></Fab>
                            <Fab size={"small"}><InstagramIcon/></Fab>
                        </Box>
                    </Box>
                        :
                    <></>
            }

            <Box flexGrow={2} height={"100%"}></Box>
        </Box>
        </Grid>
    )
}
import React, {useContext} from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHelpText} from "./helpText";
import {DebateConnectionState, useApi} from "./api";

export const BackdropContext = React.createContext(null)

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 10,
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.15)'
    }
}));

export function BackdropProvider({ children }) {
    const api = useApi()
    const helpText = useHelpText()

    const classes = useStyles()
    const [backdropOpen, setBackdropOpen] = React.useState(false)
    const [showCircularProgress, setShowCircularProgress] = React.useState(true)
    const [invisible, setInvisible] = React.useState(false)
    const [backdropMounted, setBackdropMounted] = React.useState(false)

    React.useEffect(()=>{
        setBackdropMounted(true)
    }, [])

    React.useEffect(()=>{
        const backdropOpen = api.debateConnectionState===DebateConnectionState.JOINING || api.debateConnectionState===DebateConnectionState.JOINED || helpText.helpActive
        const showCircularProgress = backdropOpen && (api.debateConnectionState===DebateConnectionState.JOINING || api.debateConnectionState===DebateConnectionState.JOINED)

        setBackdropOpen(backdropOpen)
        setShowCircularProgress(showCircularProgress)
    }, [api.debateConnectionState, helpText.helpActive])

    const backdrop = {
        backdropOpen,
        backdropMounted
    }

    return (
        <BackdropContext.Provider value={backdrop}>
            <Backdrop className={classes.backdrop} open={backdropOpen} invisible={invisible}>
                {showCircularProgress ? <CircularProgress color="inherit"/> : <></>}
            </Backdrop>
            {children}
        </BackdropContext.Provider>
    )
}

export function useBackdrop() {
    return useContext(BackdropContext);
}
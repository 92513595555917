import React from "react"
import {createTheme, CssBaseline, MuiThemeProvider, responsiveFontSizes} from "@material-ui/core";
import {useMounted} from "../hooks/UseMounted";
import Room from "./Room";
import {RoomContextProvider} from "../context/RoomContext";

let theme = responsiveFontSizes(createTheme(
    {
        typography: {
            fontFamily: [`'Montserrat'`].join(','),
        },
    }
))

export default function Layout({ children, pageContext }) {
    const renderIfMounted = useMounted()

    if (pageContext.layout === "MeetingRoom") {
        return (
            <MuiThemeProvider theme={theme}>
                <RoomContextProvider>
                    <Room title={pageContext.roomTitle}>{children}</Room>
                </RoomContextProvider>
            </MuiThemeProvider>
        )
    } else
        return renderIfMounted(
            <MuiThemeProvider theme={theme}>
                <div>
                    <CssBaseline/>
                    {children}
                </div>
            </MuiThemeProvider>
    )
}
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import React from "react";
import {Box, IconButton, Typography} from "@material-ui/core";
import {useHelpText} from "../context/helpText";
import {ArrowForward, Clear} from "@material-ui/icons";

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 520,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const HelpTooltip = (props) => {
    const helpText = useHelpText()

    return <HtmlTooltip {...props}
                        arrow
    title={
        <React.Fragment>
            <Typography variant={"subtitle2"} color="inherit" align={"center"}>{props.header}</Typography>
            <Typography variant={"caption"}>
                {
                    props.lineItems.length === 1 ?
                        <em>
                            <br/>
                            {props.lineItems[0]}
                        </em>
                        :
                        <ul>
                            {
                                props.lineItems.map(lineItem =>
                                    <li>
                                        <em>
                                            {lineItem}
                                        </em>
                                    </li>
                                )
                            }
                        </ul>
                }
            </Typography>
            <Box display={"flex"} justifyContent={"flex-end"} flexDirection={"row"}>
                <IconButton aria-label="fingerprint" color="primary" onClick={helpText.dismiss}>
                    <Clear />
                </IconButton>
                <IconButton aria-label="fingerprint" color="primary" onClick={helpText.step}>
                    <ArrowForward />
                </IconButton>
            </Box>
        </React.Fragment>
    }>
        {props.children}
    </HtmlTooltip>
}

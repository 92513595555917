import {useRef, useState} from 'react';

export const CookieValues = {
    SHOW: "SHOW",
    HIDE: "HIDE",
    NEVER_EXPIRE: 1 * 365
}

const getItem = (key) => {
    if (typeof document !== undefined) {
        const found = document.cookie.split(`; `).reduce((total, currentCookie) => {
            const item = currentCookie.split(`=`);
            const storedKey = item[0];
            const storedValue = item[1];

            return key === storedKey ? decodeURIComponent(storedValue) : total;
        }, ``);

        return found
    }
};

const setItem = (key, value, numberOfDays) => {
    let expiryString = null

    if (numberOfDays!=null) {
        const expiryDate = new Date()
        expiryDate.setTime(expiryDate.getTime() + numberOfDays * 60 * 60 * 24 * 1000)

        expiryString = expiryDate.toUTCString()
    }

    if (typeof document !== undefined) {
        console.log(`updating document.cookie for key [${key}], expires=${expiryString}`)
        console.log(value)

        console.log(document.cookie)
        document.cookie = `${key}=${value}; expires=${expiryString}; path=/`;
        console.log(document.cookie)
    }
};

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
/*export const useCookie = (key, defaultValue) => {
    const getCookie = () => getItem(key) || defaultValue;
    const [cookie, setCookie] = useState(getCookie());

    const updateCookie = (value, numberOfDays) => {
        setCookie(value);
        setItem(key, value, numberOfDays);
    };

    return [cookie, updateCookie];
};*/

export const useCookie = (key, defaultValue) => {
    const getCookie = () => {
        const value = getItem(key)

        console.log(`get item [${key}=${value}]`)

        if (value) {
            return JSON.parse(value)
        } else {
            return defaultValue
        }
    }

    const [cookie, setCookie] = useState(getCookie());

    const updateCookie = (value, numberOfDays) => {
        console.log(`updating cookie [${key}] to value:`)
        console.log(value)

        setCookie(value);
        setItem(key, JSON.stringify(value), numberOfDays);
    };

    return [cookie, updateCookie];
};

function encode(value) {
    return value.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
}

function decode(value) {
    if (value.length % 4 != 0){
        value += ('===').slice(0, 4 - (value.length % 4));
    }

    return value.replace(/-/g, '+').replace(/_/g, '/');
}

export const useRefCookie = (key, defaultValue) => {
    const getCookie = () => {
        const value = getItem(key)

        if (value) {
            const cookieDecoded = decode(value)
            const base64decoded = atob(cookieDecoded)
            return JSON.parse(base64decoded)
        } else {
            return defaultValue
        }
    }

    const cookie = useRef(getCookie());

    const updateCookie = (value, numberOfDays) => {
        cookie.current  = value;

        const JSONValue = JSON.stringify(value)
        const base64Encoded = btoa(JSONValue)
        const cookieEncoded = encode(base64Encoded)

        setItem(key, cookieEncoded, numberOfDays);
    };

    return [cookie, updateCookie];
};
import {Box, Typography} from "@material-ui/core";
import React from "react";

function Motion(props) {
    return <Box maxWidth={800}>
        <Typography variant={"overline"} align={"left"}>
            <Box sx={{
                lineHeight: 1.9,
                fontWeight: "light"
            }}><b>Motion</b>&nbsp;/&nbsp;{props.body}</Box>
        </Typography>
    </Box>
}

export default Motion
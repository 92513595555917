import React, {useEffect} from 'react';
import {useAuth} from "../context/auth";
import LoginDialog from "./LoginDialog";
import {navigate} from "../../.cache/commonjs/gatsby-browser-entry";

export default function Protected({ children }) {
    const auth = useAuth()

    useEffect(()=>{
        if (auth?.identity == null) {
            setLoginOpen(true)
        }
    }, [])

    const [loginOpen, setLoginOpen] = React.useState(false)

    function login() {
        setLoginOpen(true)
    }

    function loginClosed(success) {
        setLoginOpen(false)

        if (!success) {
            navigate("/", { replace: true})
        }
    }

    return (
        <div>
        <LoginDialog open={loginOpen} onClose={loginClosed}/>
        {children}
        </div>
    )
}

/*
export default function Protected({ children, location }) {
    const auth = useAuth()

    if (auth?.identity == null) {
        // noinspection JSIgnoredPromiseFromCall
        if (typeof window !== `undefined`) {
            navigate("/login", { replace: true, state: {from: location}})
        }
        return null
    } else return (
        <div>
        {children}
        </div>
    )
}
 */
import React from "react";
import {AuthCode} from "../context/auth";

export function UseAuthMessage(authCode, onClose) {
    const [showMessage, setShowMessage] = React.useState(false);
    const [severity, setSeverity] = React.useState("");
    const [authMessageTitle, setAuthMessageTitle] = React.useState("");
    const [authMessageBody, setAuthMessageBody] = React.useState("");

    React.useEffect(()=>{
        console.log(`evaluating authCode: [${JSON.stringify(authCode)}]`)

        switch (authCode) {
            case null:
            case "":
                setSeverity("info")
                setAuthMessageTitle("")
                setAuthMessageBody("")
                setShowMessage(false)
                break
            case AuthCode.AuthenticationSuccessful:
                setSeverity("success")
                setAuthMessageTitle("Login")
                setAuthMessageBody("You have logged in")
                //setShowMessage(true)
                break
            case AuthCode.VerifySignupSuccessful:
                setSeverity("success")
                setAuthMessageTitle("You have signed up")
                setAuthMessageBody("You have confirmed your verification code. Please use your user name and password to log in.")
                setShowMessage(true)
                break
            case AuthCode.SignupSuccessful:
                setSeverity("success")
                setAuthMessageTitle("Verification code sent")
                setAuthMessageBody("Please check your emails and enter the most recent verification code you have received.")
                setShowMessage(true)
                break
            case AuthCode.ConfirmNewPasswordSuccessful:
                setSeverity("success")
                setAuthMessageTitle("Password changed")
                setAuthMessageBody("You have successfully changed your password. Please use your new password to log in.")
                setShowMessage(true)
                break
            case AuthCode.ResendVerificationCodeSuccessful:
                setSeverity("success")
                setAuthMessageTitle("Verification code sent")
                setAuthMessageBody("Please check your emails and enter the most recent verification code you have received")
                setShowMessage(true)
                break
            case AuthCode.InitiatePasswordResetSuccessful:
                setSeverity("success")
                setAuthMessageTitle("Password reset initiated")
                setAuthMessageBody("Please reset you password in the password reset form. Use the verification code that we have just sent you to your email address.")
                setShowMessage(true)
                break
            case AuthCode.PasswordResetCompleted:
                setSeverity("success")
                setAuthMessageTitle("Password reset completed")
                setAuthMessageBody("Please use your email and new password to sign in.")
                setShowMessage(true)
                break
            case AuthCode.UnknownError:
                setSeverity("error")
                setAuthMessageTitle("An unknown error occurred")
                setAuthMessageBody("Please try again later.")
                setShowMessage(true)
                break
            case AuthCode.InternalError:
                setSeverity("error")
                setAuthMessageTitle("An internal error occurred")
                setAuthMessageBody("Please try again later.")
                setShowMessage(true)
                break
            case AuthCode.UserNotFound:
                setSeverity("error")
                setAuthMessageTitle("Invalid user name")
                setAuthMessageBody("Please make sure you have entered your user name correctly.")
                setShowMessage(true)
                break
            case AuthCode.InvalidPassword:
                setSeverity("error")
                setAuthMessageTitle("Invalid password")
                setAuthMessageBody("The password you entered is not valid. Please make sure you have entered a valid password.")
                setShowMessage(true)
                break
            case AuthCode.InvalidLoginData:
                setSeverity("error")
                setAuthMessageTitle("Invalid login data")
                setAuthMessageBody("The email address or the password is invalid. Please make sure you filled in both fields and you use a valid email/password combination.")
                setShowMessage(true)
                break
            case AuthCode.PasswordMismatch:
                setSeverity("error")
                setAuthMessageTitle("Password error")
                setAuthMessageBody("The password you entered does not match our records. Please make sure you have entered the correct password.")
                setShowMessage(true)
                break
            case AuthCode.RegistrationVerificationIncomplete:
                setSeverity("info")
                setAuthMessageTitle("Registration verification needed")
                setAuthMessageBody("Your registration is not confirmed yet. Please confirm your registration by entering the verification code we have sent to your email address.")
                setShowMessage(true)
                break
            case AuthCode.NewPasswordRequired:
                setSeverity("info")
                setAuthMessageTitle("New password is required")
                setAuthMessageBody("Please enter your new password in the form.")
                setShowMessage(true)
                break
            case AuthCode.AuthCodeMismatch:
                setSeverity("error")
                setAuthMessageTitle("Verification code does not match")
                setAuthMessageBody("Please make sure you entered the most recent verification code you received to your email address.")
                setShowMessage(true)
                break
            case AuthCode.InvalidAuthCode:
                setSeverity("error")
                setAuthMessageTitle("Invalid verification code")
                setAuthMessageBody("Please make sure you have entered the verification code and it is in the correct format. The code consist of 6 digits, no spaces and no special characters. Also, make sure your email address is correct.")
                setShowMessage(true)
                break
            case AuthCode.VerificationCodeLimitExceeded:
                setSeverity("error")
                setAuthMessageTitle("Password reset request failed")
                setAuthMessageBody("You have entered the wrong verification code too many times. Please wait 15 minutes and try again later.")
                setShowMessage(true)
                break
            case AuthCode.ForgotPasswordRegistrationVerificationIncomplete:
                setSeverity("error")
                setAuthMessageTitle("Your account is not verified yet")
                setAuthMessageBody("You can not reset your password until your account is verified. Enter your username on the login page and click the login button. An email verification code will be sent to you, that you can use to activate your account. When your account is activated, you will be able to initiate a password reset.")
                setShowMessage(true)
                break
            case AuthCode.VerificationPasswordDoNotMatch:
                setSeverity("error")
                setAuthMessageTitle("Password don't match")
                setAuthMessageBody("Please make sure that the passwords in the 'Password' and 'Verify Password' fields are identical.")
                setShowMessage(true)
                break
            case AuthCode.InvalidRegistrationToken:
                setSeverity("error")
                setAuthMessageTitle("Invalid registration token")
                setAuthMessageBody("The value of the registration token is invalid. Make sure you have filled out the field, use the token obtained from your administrator.")
                setShowMessage(true)
                break
            case AuthCode.UsernameExists:
                setSeverity("error")
                setAuthMessageTitle("Email is already registered")
                setAuthMessageBody("The email you entered is already in use. If you have registered an account with this address, try to log using your password. If you forgot your password, you can initiate a password reset from the login page.")
                setShowMessage(true)
                break
            case AuthCode.RegistrationTokenExpired:
                setSeverity("error")
                setAuthMessageTitle("Registration token expired")
                setAuthMessageBody("The registration token you entered is expired. Please obtain another token from your administrator.")
                setShowMessage(true)
                break
            case AuthCode.PasswordResetRequired:
                setSeverity("warning")
                setAuthMessageTitle("Password Reset Required")
                setAuthMessageBody("For security reasons, please reset your password")
                setShowMessage(false)
                break
            default:
                setSeverity("error")
                setAuthMessageTitle("Unexpected internal error")
                setAuthMessageBody("Please try again later.")
                setShowMessage(true)
                break
        }
    }, [authCode])

    const closeAuthMessage = (event, reason) => {
        setShowMessage(false);
        onClose()
    };

    return [showMessage, severity, authMessageTitle, authMessageBody, closeAuthMessage]
}
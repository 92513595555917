import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {createTheme, Fab, MenuItem, Snackbar, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {navigate} from "../../.cache/commonjs/gatsby-browser-entry";
import LoginDialog from "./LoginDialog";
import {useAuth} from "../context/auth";
import {DebateConnectionState, useApi} from "../context/api"
import RegisterDialog from "./RegisterDialog";
import {Alert, AlertTitle} from "@material-ui/lab";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1200,
        margin: theme.spacing(0, 0, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: 1
    },
    menu: {
        backgroundColor: "white",
        //transform: "translateY(150px)",
        transition: "0.1s all linear",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "4.5rem",
        zIndex: 2,
        cursor: "default"
    },
    hero: {
        transition: "0.2s all ease",
    }
}));

let theme = createTheme(
    {
        typography: {
            fontFamily: [`'Montserrat'`].join(','),
        },
    }
)

export default function Menu(props) {
    const classes = useStyles()
    const auth = useAuth()
    const api = useApi()

    if (!api || !auth) {
        return null
    }

    const medium = useMediaQuery(theme.breakpoints.up('md'));
    const small = useMediaQuery(theme.breakpoints.up('sm'));

    const postLoginCallbackFunction = React.useRef(null)
    const [loginOpen, setLoginOpen] = React.useState(false)

    const params = new URLSearchParams(location?.search);
    const debateIdParam = params.get("debateId")
    const registrationTokenParam = params.get("registrationToken")

    const [registrationOpen, setRegistrationOpen] = React.useState(false)
    const [joiningDebate, setJoiningDebate] = React.useState(false)
    const registrationTokenProcessed = React.useRef(false)
    const [debateId, setDebateId] = React.useState(null)
    const [registrationToken, setRegistrationToken] = React.useState(null)
    const loginSubmitted = React.useRef(false)
    const [showConnectionFailedMessage, setShowConnectionFailedMessage] = React.useState(false);

    function onCloseRegisterDialog() {
        setRegistrationOpen(false)
        navigate("/")
    }

    if (debateIdParam!=null && debateId==null) {
        setDebateId(debateIdParam)
    }

    if (registrationTokenParam!=null && registrationToken==null) {
        setRegistrationToken(registrationTokenParam)
    }

    React.useEffect(() => {
        switch (api.debateConnectionState) {
            case DebateConnectionState.JOINING:
                break
            case DebateConnectionState.FAILED:
                if (debateId !== null) {
                    setShowConnectionFailedMessage(true)
                }
                break
        }

        if (auth.identity != null) {
            if (!(debateId == null || joiningDebate)) {
                setJoiningDebate(true)

                api.joinDebate(debateId).then(result => {
                }).catch(err => {
                })
            }
        }

        if (debateId!=null) {
            if (!(loginOpen || loginSubmitted.current || joiningDebate) && auth.identity===null) {
                openLoginDialog()
            }
        }
    }, [api.debateConnectionState, auth.identity, debateId])

    if (registrationToken!=null) {
        if (!registrationOpen && !registrationTokenProcessed.current) {
            setRegistrationOpen(true)
            registrationTokenProcessed.current = true
        }
    }

    function loginClose(success) {
        if (loginOpen) {
            loginSubmitted.current = true
            setLoginOpen(false)
        }

        if (postLoginCallbackFunction.current) {
            postLoginCallbackFunction.current(success)
            postLoginCallbackFunction.current = false
        }
    }

    function openLoginDialog(postLoginCallback) {
        postLoginCallbackFunction.current = postLoginCallback

        if (!loginOpen) {
            loginSubmitted.current = false
            setLoginOpen(true)
        }
    }

    function postLoginCallback(success) {
        if (success) {
            // navigate("/join", { replace: true})
        }
    }

    function login() {
        openLoginDialog(postLoginCallback)
    }

    function logout() {
        auth.signOut()
        navigate("/")
    }

    function link(to) {
        console.log(`navigating to ${to}`)
        return navigate(to)
    }

    function MenuItem(props) {
        let {title, onClick, link, backgroundColor, selectedTextColor, variant, letterSpacing, fontWeight, ml, mr, hidden} = props
        const [textColor, setTextColor] = React.useState("black")

        if (ml==null) {
            ml=0
        }

        if (mr==null) {
            mr=0
        }

        if (variant==null) {
            variant="subtitle1"
        }

        if (letterSpacing==null) {
            letterSpacing=2.2
        }

        if (fontWeight==null) {
            fontWeight=200
        }

        function mouseEnter() {
            setTextColor(selectedTextColor==null?"#a5a5a5":selectedTextColor)
        }

        function mouseLeave() {
            setTextColor("black")
        }

        function handleOnClick() {
            if (onClick) {
                onClick()
            }

            if (link) {
                navigate(link)
            }
        }

        return (
            <Box hidden={hidden} borderRadius={"7px"} mr={1}  pt={0.5} pb={0.5} pl={1} pr={1} sx={{backgroundColor: backgroundColor}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={handleOnClick} ml={ml} mr={mr}>
                <Typography variant={variant} style={{color:textColor, letterSpacing:letterSpacing, fontWeight:fontWeight}}>
                    {title}
                </Typography>
            </Box>
        )
    }

    function goHome() {
        navigate("/")
    }

    function closeConnectionFailedMessage() {
        setShowConnectionFailedMessage(false)
    }

    let loginLogoutMenu = <MenuItem title={"Login"} variant={"h6"} fontWeight={300} onClick={login}/>
    let loggedInUserName = <></>

    if (auth?.identity) {
        loginLogoutMenu = <MenuItem title={"Logout"} variant={"h6"} fontWeight={300} onClick={logout}/>
        loggedInUserName = <Box>
                            <Typography variant="subtitle2" style={{color:"black"}}>
                                logged in as <i>{auth.userName}</i>
                            </Typography>
                           </Box>
    }

    return (
        <>
            <Box className={classes.menu} sx={{opacity: 1, borderBottom: "1px solid #40405030"}} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Snackbar open={showConnectionFailedMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={closeConnectionFailedMessage}>
                    <Alert onClose={closeConnectionFailedMessage} severity={"error"} sx={{ width: '100%' }} >
                        <AlertTitle>Unable to join</AlertTitle>Unable to join to debate {debateId}. Please make sure you
                        use a valid debate id.<br/><br/>Note that if the debate has already started, you will not be able to join even with
                        a valid debate id.<br/><br/>
                    </Alert>
                </Snackbar>
                <LoginDialog open={loginOpen} onClose={loginClose}/>
                <RegisterDialog onClose={onCloseRegisterDialog} open={registrationOpen} registrationToken={registrationToken}/>
                <MenuItem title={"debate.club"} link={"/"} variant={"h5"} letterSpacing={1} fontWeight={600} ml={2}/>
                {medium?loggedInUserName:<></>}
                <Box display={"flex"} flexDirection={"row"} mr={3}>
                    <MenuItem title={"What is Debating?"} variant={"h6"} fontWeight={300} letterSpacing={1} link={"/whatisdebate"} hidden={!medium}/>
                    <MenuItem title={"Product"} variant={"h6"} link={"/product"} fontWeight={300} letterSpacing={1} />
                    <MenuItem title={"Pricing"} variant={"h6"} link={"/pricing"} fontWeight={300} letterSpacing={1} hidden={!medium}/>
                    <MenuItem title={"Docs"} variant={"h6"} link={"/doc"} fontWeight={300} letterSpacing={1}/>
                    <MenuItem title={"Debate"} variant={"h6"} onClick={api?.routeToStage} backgroundColor={"#a0f5ff"} hidden={!small} fontWeight={300} letterSpacing={1}/>
                    {loginLogoutMenu}
                </Box>
            </Box>
        </>
    )
}
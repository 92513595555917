exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adjudicators-js": () => import("./../../../src/pages/adjudicators.js" /* webpackChunkName: "component---src-pages-adjudicators-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-debate-js": () => import("./../../../src/pages/debate.js" /* webpackChunkName: "component---src-pages-debate-js" */),
  "component---src-pages-debrief-js": () => import("./../../../src/pages/debrief.js" /* webpackChunkName: "component---src-pages-debrief-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dsar-js": () => import("./../../../src/pages/dsar.js" /* webpackChunkName: "component---src-pages-dsar-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-lobby-js": () => import("./../../../src/pages/lobby.js" /* webpackChunkName: "component---src-pages-lobby-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-preproom-first-government-js": () => import("./../../../src/pages/preproom_first_government.js" /* webpackChunkName: "component---src-pages-preproom-first-government-js" */),
  "component---src-pages-preproom-first-opposition-js": () => import("./../../../src/pages/preproom_first_opposition.js" /* webpackChunkName: "component---src-pages-preproom-first-opposition-js" */),
  "component---src-pages-preproom-second-government-js": () => import("./../../../src/pages/preproom_second_government.js" /* webpackChunkName: "component---src-pages-preproom-second-government-js" */),
  "component---src-pages-preproom-second-opposition-js": () => import("./../../../src/pages/preproom_second_opposition.js" /* webpackChunkName: "component---src-pages-preproom-second-opposition-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-rejoin-js": () => import("./../../../src/pages/rejoin.js" /* webpackChunkName: "component---src-pages-rejoin-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-whatisdebate-js": () => import("./../../../src/pages/whatisdebate.js" /* webpackChunkName: "component---src-pages-whatisdebate-js" */)
}


import React, {useContext} from "react";

export const HelpTextContext = React.createContext(null)

export function HelpTextProvider({ children }) {
    const tooltipSetters = React.useRef(new Map())
    const keyOrder = React.useRef([])
    const currentKey = React.useRef(null)
    const [helpActive, setHelpActive] = React.useState(false)
    const [ready, setReady] = React.useState(false)

    function reset() {
        keyOrder.current = []
        currentKey.current = null
        tooltipSetters.current = new Map()
        setHelpActive(false)
        setReady(false)
    }

    function registerTooltip(key, setter) {
        console.log(`registering tooltip ${key}`)
        tooltipSetters.current.set(key, setter)

        checkReady()
    }

    function setKeyOrder(value) {
        console.log(`setting key order`)
        console.log(value)

        keyOrder.current = value

        checkReady()
    }

    function getTooltipSetterByKey(key) {
        console.log(`getting key ${key}`)

        return tooltipSetters.current.get(key)
    }

    function getCurrentTooltipSetter() {
        console.log(`getting current tooltip setter [index=${currentKey.current}, key=${keyOrder.current[currentKey.current]}]`)
        return getTooltipSetterByKey(keyOrder.current[currentKey.current])
    }

    function nextTooltip() {
        if (currentKey.current+1<keyOrder.current.length) {
            currentKey.current = currentKey.current+1
            console.log(`selected next tooltip [${keyOrder.current[currentKey.current]}]`)

            return true
        } else {
            console.log(`no more tooltip to show`)
            return false
        }
    }

    function getNextTooltipSetter() {
        if (nextTooltip()) {
            return getCurrentTooltipSetter()
        } else {
            return null
        }
    }

    function step() {
        const currentSetter = getCurrentTooltipSetter()
        const nextSetter = getNextTooltipSetter()

        currentSetter(false)

        if (nextSetter) {
            nextSetter(true)
        } else {
            setHelpActive(false)
        }
    }

    function dismiss() {
        const setter = getCurrentTooltipSetter()
        setter(false)

        setHelpActive(false)
    }

    function start() {
        console.log(`start help text cycle`)
        console.log(tooltipSetters)

        tooltipSetters.current.forEach((setter)=>setter(false))

        currentKey.current = 0
        const setter = getCurrentTooltipSetter()

        if (setter==null) {
            console.error(`setter is not registered for [${keyOrder.current[0]}]`)
            console.log(`registered keys:`)
            console.log(keyOrder)
        } else {
            setter(true)
        }

        setHelpActive(true)
    }

    function checkReady() {
        const tooltipSetterKeys = Array.from( tooltipSetters.current.keys())

        const isReady = keyOrder.current.length>0 && keyOrder.current.every(elem => {
            const included = tooltipSetterKeys.includes(elem)

            return included
        })

        setReady(isReady)
    }


    const helpText = {
        tooltipSetters,
        reset,
        registerTooltip,
        setKeyOrder,
        step,
        start,
        dismiss,
        helpActive,
        ready
    }

    return (
        <HelpTextContext.Provider value={helpText}>
            {children}
        </HelpTextContext.Provider>
    )
}

export function useHelpText() {
    return useContext(HelpTextContext);
}
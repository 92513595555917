import Box from "@material-ui/core/Box";
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import React, {useState} from "react";
import {useFormik} from "formik";
import {AuthCode, useAuth} from "../context/auth";
import {makeStyles} from "@material-ui/core/styles";
import {AuthMessageSnackbar} from "./AuthMessageSnackbar";
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 0, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh"
    },
    registerForm: {
        width: "100%",
        margin: 2
    },
    registerText: {
        width: "100%"
    }
}));

function RegisterDialog(props) {
    const auth = useAuth();
    const classes = useStyles()

    const { onClose, open, registrationToken } = props;

    const [subscriptionStage, setSubscriptionStage] = useState("subscribe")
    const [authCode, setAuthCode] = React.useState("");
    const [formLocked, setFormLocked] = React.useState(false);
    const [submitLocked, setSubmitLocked] = React.useState(false);

    function showAuthInternalError(source, err) {
        console.error(`${source}: internal error [${JSON.stringify(err)}]`)
    }

    function showAuthMessage(authCode) {
        setAuthCode("")
        setAuthCode(authCode)
    }

    React.useEffect(()=>{
        registerFormik.resetForm()
        setAuthCode("")
        setFormLocked(false)
    }, [props.open])

    const verificationObject = {
        token: yup
            .string('Enter your registration token')
            .required('Registration token is required'),
        emailAddress: yup
            .string('Enter your email address')
            .email('Invalid email address')
            .required('Email address is required'),
        userName: yup
            .string('Enter your user name')
            .min(6, 'User name must be at least 6 characters long')
            .required('User name is required'),
        passwordFirst: yup
            .string('Enter your password')
            .min(6, 'Password must be at least 6 characters long')
            .required('Password is required'),
        passwordSecond: yup
            .string('Verify your password')
            .min(6, 'Password must be at least 6 characters long')
            .required('Password is required'),
    }

    if (subscriptionStage==="verify") {
        verificationObject.verificationCode = yup
            .string('Enter your verification code')
            .length(6, 'Verification code must be 6 characters long')
            .required('Verification code is required')
    }

    let registrationTokenInitialValue = ''

    if (registrationToken==null) {
        registrationTokenInitialValue = ''
    } else {
        registrationTokenInitialValue = registrationToken
    }

    const registerFormik = useFormik({
        initialValues: {
            token: registrationTokenInitialValue,
            emailAddress: '',
            userName: '',
            passwordFirst: '',
            passwordSecond: '',
            verificationCode: ''
        },
        validationSchema: yup.object(verificationObject),
        validate: (values) => {
            const errors = {}

            if (values.passwordFirst!==values.passwordSecond) {
                errors.passwordSecond = "Passwords do not match"
            }

            return errors
        },
        onSubmit: async (values) => {
            try {
                switch (subscriptionStage) {
                    case "subscribe": {
                        if (registerFormik.values.passwordFirst!==registerFormik.values.passwordSecond) {
                            showAuthMessage(AuthCode.VerificationPasswordDoNotMatch)
                        } else {
                            auth.signup(registerFormik.values.token, registerFormik.values.emailAddress, registerFormik.values.userName, registerFormik.values.passwordFirst)
                                .then(authResult=>{
                                    showAuthMessage(authResult.authCode)

                                    if (authResult.authCode===AuthCode.SignupSuccessful) {
                                        console.log("proceed to verification")
                                        setSubscriptionStage("verify")
                                        setFormLocked(true)
                                    } else {
                                    }
                                }).catch((err)=>{
                                    showAuthInternalError("verifySignup", err)
                                })
                        }

                        return
                    }
                    case "verify": {
                        auth.verifySignup(registerFormik.values.userName, registerFormik.values.verificationCode).then((authResult)=>{
                            showAuthMessage(authResult.authCode)

                            if (authResult.authCode===AuthCode.VerifySignupSuccessful) {
                                console.log("verification succeeded")
                                setSubmitLocked(true)

                                new Promise(resolve => setTimeout(()=>{
                                    handleCancel()
                                    resolve()
                                }, 5000));
                            } else {

                            }
                        })

                        return
                    }
                    default: {
                        handleCancel()
                        return
                    }
                }
            } catch (err) {
                console.error(`error while signing up: ${err}`)
            }
        },
    });

    const handleCancel = () => {
        console.log("handleCancel called")

        setSubscriptionStage("subscribe")
        registerFormik.resetForm()
        setFormLocked(false)
        setSubmitLocked(false)
        onClose()
    }

    function getButtonLabel() {
        switch (subscriptionStage) {
            case "verify": return "Verify"
            case "subscribe": return "Subscribe"
            default:
                console.error(`invalid unexpected value in subscriptionStage [${subscriptionStage}]`)
                return "Subscribe"
        }
    }

    function onCloseAuthMessage() {
        setAuthCode(null)
    }

    return <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <Box p={5}>
            <form className={classes.registerForm} noValidate autoComplete="off">
            <DialogTitle id="form-dialog-title" disableTypography={true} ><Typography variant="h4" align={"center"}>Subscribe</Typography></DialogTitle>
            <DialogContent>
                <AuthMessageSnackbar authCode={authCode} onClose={onCloseAuthMessage}/>
                <DialogContentText>
                    To subscribe to this website, please enter your details below.
                    Please note that debate.club is in private beta, you will need a registration token in order to proceed with signing up.<br/><br/>
                    By submitting this form, you indicate you have read and accept our <a style={{"text-decoration": "none"}} target={"_blank"} href={"/privacy"}>privacy policy</a> and <a style={{"text-decoration": "none"}} target={"_blank"} href={"/terms"}>terms of use</a>.
                </DialogContentText>
                    <Box pb={1}>
                        <TextField disabled={formLocked} label={"Registration Token"} id={"token"} className={classes.registerText} value={registerFormik.values.token} onChange={registerFormik.handleChange} error={registerFormik.errors.token && registerFormik.touched.token && Boolean(registerFormik.errors.token)} helperText={registerFormik.errors.token && registerFormik.touched.token && registerFormik.errors.token} helperText={"To get a token, use the 'Request Beta Access' button on our main page."}/>
                    </Box>
                    <Box pb={1}>
                        <TextField disabled={formLocked} label={"Email address"} id={"emailAddress"} className={classes.registerText} value={registerFormik.values.emailAddress} onChange={registerFormik.handleChange}  error={registerFormik.errors.emailAddress && registerFormik.touched.emailAddress && Boolean(registerFormik.errors.emailAddress)} helperText={registerFormik.errors.emailAddress && registerFormik.touched.emailAddress && registerFormik.errors.emailAddress} helperText={"Enter a valid email address that is not registered yet"}/>
                    </Box>
                    <Box pb={1}>
                        <TextField disabled={formLocked} label={"User name"} id={"userName"} className={classes.registerText} value={registerFormik.values.userName} onChange={registerFormik.handleChange}  error={registerFormik.errors.userName && registerFormik.touched.userName && Boolean(registerFormik.errors.userName)} helperText={registerFormik.errors.userName && registerFormik.touched.userName && registerFormik.errors.userName} helperText={"Enter at least 6 characters. You can use letters, numbers and underscore"}/>
                    </Box>
                    <Box pb={1}>
                        <TextField disabled={formLocked} label="Password" type={"password"} id={"passwordFirst"} className={classes.registerText} value={registerFormik.values.passwordFirst} onChange={registerFormik.handleChange}  error={registerFormik.errors.passwordFirst && registerFormik.touched.passwordFirst && Boolean(registerFormik.errors.passwordFirst)} helperText={registerFormik.errors.passwordFirst && registerFormik.touched.passwordFirst && registerFormik.errors.passwordFirst} helperText={"Enter at least 6 characters. It must contain upper case, lower case letters, digits and special characters."}/>
                    </Box>
                    <Box pb={1}>
                        <TextField disabled={formLocked} label="Re-enter password" type={"password"} id={"passwordSecond"} className={classes.registerText} value={registerFormik.values.passwordSecond} onChange={registerFormik.handleChange}  error={registerFormik.errors.passwordSecond && registerFormik.touched.passwordSecond && Boolean(registerFormik.errors.passwordSecond)} helperText={registerFormik.errors.passwordSecond && registerFormik.touched.passwordSecond && registerFormik.errors.passwordSecond} helperText={"Repeat the same password as above"}/>
                    </Box>
                    <Collapse in={subscriptionStage==="verify"}>
                        <Box>
                            <TextField disabled={submitLocked} helperText={"We have sent you a verification code to the email address provided above"} label="Verification code" id={"verificationCode"} className={classes.registerText} value={registerFormik.values.verificationCode} onChange={registerFormik.handleChange}  error={registerFormik.errors.verificationCode && registerFormik.touched.verificationCode && Boolean(registerFormik.errors.verificationCode)} helperText={registerFormik.errors.verificationCode && registerFormik.touched.verificationCode && registerFormik.errors.verificationCode} />
                        </Box>
                    </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={registerFormik.handleSubmit} color="primary" disabled={submitLocked} type={"submit"}>
                    {getButtonLabel()}
                </Button>
            </DialogActions>
            </form>
        </Box>
    </Dialog>
}

export default RegisterDialog
